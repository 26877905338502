import { Entity, Scenario, Updatable } from '../..';
import { observable, computed, action } from 'mobx';
import { ScenarioNodePort } from '../port/ScenarioPort';
import { isEmpty } from '../../../utils';

export type ScenarioNodePosition = { x: number, y: number };
export class ScenarioNodeSettings extends Updatable {
    constructor(settings?: Partial<ScenarioNodeSettings>) {
        super();
        if (settings) this.update(settings);
    }

    @observable position: ScenarioNodePosition;

    @action
    update(entity: Partial<ScenarioNodeSettings>) {
        super.update(entity);
    }

    @action
    static fromJson(json: any): ScenarioNodeSettings {
        return new ScenarioNodeSettings({
            position: json.position
        });
    }

    static default() {
        return new ScenarioNodeSettings({
            position: { x: 350, y: 22 }
        });
    }
}

export declare type ScenarioNodeType = 'event' | 'action' | 'other';
// Scenario.node.EmailOpenedScenarioEvent
export enum ScenarioNodeActionType {
    // course
    CourseStarted = 'CourseStartedScenarioEvent',
    CourseCompleted = 'CourseCompletedScenarioEvent',
    // course item
    CourseItemStarted = 'CourseItemStartedScenarioEvent',
    CourseItemCompleted = 'CourseItemCompletedScenarioEvent',
    CourseItemTaskCompleted = 'CourseItemTaskCompletedScenarioEvent',
    // email
    SendEmail = 'SendEmailScenarioAction',
    EmailOpened = 'EmailOpenedScenarioEvent',
    EmailClicked = 'EmailClickedScenarioEvent',

    // tags
    AddLeadTags = 'AddLeadTagsScenarioAction',
    DeleteLeadTags = 'DeleteLeadTagsScenarioAction',

    // groups
    AddContactGroups = 'AddLeadGroupsScenarioAction',
    DeleteContactGroups = 'DeleteLeadGroupsScenarioAction',

    // other
    Delay = 'DelayScenarioAction',
    Condition = 'ConditionScenarioAction',
    Switcher = 'SwitcherScenarioAction',

    UpdateContactAttribute = 'UpdateLeadAttributeScenarioAction',
    CustomEventStarted = 'CustomEventStartedScenarioEvent',
    CreatePurchaseOrder = 'CreateLeadPurchaseOrderScenarioAction',
    EditPurchaseOrder = 'EditLeadPurchaseOrderScenarioAction',

    // task
    AddTask = 'AddAdminTaskScenarioAction',
    TaskCompleted = 'AdminTaskCompletedScenarioEvent',
    TaskNotCompleted = 'AdminTaskNotCompletedScenarioEvent',

    // note
    AddNote = 'AddLeadNoteScenarioAction',

    // license
    AddLicense = 'AddLeadLicenseScenarioAction',
    DeleteLicense = 'DeleteLeadLicenseScenarioAction',

    // responsible
    AddAdminResponsible = 'AddAdminResponsibleScenarioAction',
    DeleteAdminResponsible = 'DeleteAdminResponsibleScenarioAction',

    // broadcast list
    AddToBroadcastList = 'AddLeadToBroadcastListScenarioAction',
    DeleteFromBroadcastList = 'DeleteLeadFromBroadcastListScenarioAction',

    //webhook
    SendWebhook = 'SendWebhookScenarioAction',
    SendMessage = 'SendMessageScenarioAction',

    // site
    FormSubmitted = 'FormSubmittedScenarioEvent',

    // flow
    FlowStarted = "FlowStartedScenarioEvent",
    FlowBeforeStarted = "FlowBeforeStartedScenarioEvent",
    FlowCompleted = "FlowCompletedScenarioEvent",
    FlowStudentAdded = "FlowStudentAddedScenarioEvent",

    //webinar
    WebinarTimer = "WebinarTimerScenarioEvent",
    WebinarRegistration = "WebinarRegistrationScenarioEvent",
    WebinarStarted = "WebinarStartedScenarioEvent",
    WebinarCompleted = "WebinarCompletedScenarioEvent",
    RegisterWebinar = "RegisterWebinarScenarioAction",

    //product
    ProductPurchased = "ProductPurchasedScenarioEvent",
    ProductPurchaseOrderCreated = "ProductPurchaseOrderCreatedScenarioEvent",
    ProductSubscriptionRenewalPaymentSucceeded = "ProductSubscriptionRenewalPaymentSucceededScenarioEvent",
    ProductSubscriptionRenewalPaymentFailed = "ProductSubscriptionRenewalPaymentFailedScenarioEvent",
    ProductSubscriptionExpired = "ProductSubscriptionExpiredScenarioEvent",
    ProductSubscriptionPeriodCheck = 'LeadProductSubscriptionPeriodCheckedScenarioEvent',
    ProductSubscriptionCancelled = 'ProductSubscriptionCancelledScenarioEvent',


    // chat bot
    ChatBotStarted = "ChatBotStartedScenarioEvent",
    ChatBotRequestData = "ChatBotRequestDataScenarioAction",

    // scenario
    StartScenario = "StartScenarioScenarioAction",
    ScenarioStarted = "ScenarioStartedScenarioEvent",

    // simple
    StudentRegistered = "StudentRegisteredScenarioEvent",
    ContactAdded = "LeadAddedScenarioEvent",
    ContactTagsAdded = "LeadTagsAddedScenarioEvent",
    ContactGroupsAdded = "LeadGroupsAddedScenarioEvent",

    // user notification
    UserNotification = 'UserNotificationScenarioAction',

    //order
    PurchaseOrderCreated = 'PurchaseOrderCreatedScenarioEvent',
    PurchaseOrderPaid = 'PurchaseOrderPaidScenarioEvent',
    PurchaseOrderCreatedGlobal = 'PurchaseOrderCreatedScenarioGlobalEvent',
    PurchaseOrderPaidGlobal = 'PurchaseOrderPaidScenarioGlobalEvent',
    AddPurchaseOrderTags = 'AddPurchaseOrderTagsScenarioAction',
    AddPurchaseOrderPayment = 'AddPurchaseOrderPaymentScenarioAction',

    // quiz
    QuizCompleted = 'QuizCompletedScenarioEvent',

    // pipeline
    PipelineDealAdded = 'PipelineDealAddedScenarioEvent',
    PipelineDealStageChanged = 'PipelineDealStageChangedScenarioEvent',
    PipelineChangeDealStage = 'PipelineDealChangeStageScenarioAction',
    PipelineAddDeal = 'PipelineDealAddScenarioAction',
    PipelineChangeDealResponsibleAdmin = 'PipelineDealChangeResponsibleAdminScenarioAction',

    // app
    AddAppTgGroupUser = 'AddLeadToTelegramGroupScenarioAction',
    DeleteAppTgGroupUser = 'DeleteLeadFromTelegramGroupScenarioAction',
    CheckTelegramChannelHasMember = 'CheckTelegramChannelHasMemberScenarioAction',
    TelegramGroupMessageReceived = 'TelegramGroupMessageReceivedScenarioEvent',

    // bulk
    PauseLeadProductSubscriptions = 'PauseLeadProductSubscriptionsScenarioAction',
    CancelLeadProductSubscriptions = 'CancelLeadProductSubscriptionsScenarioAction',

    // partnership
    PartnershipPartnerPurchase = 'PartnerPurchaseScenarioEvent',
    PartnershipStudentPurchase = 'StudentPartnerPurchaseScenarioEvent',
    PartnershipPartnerRegistration = 'PartnerRegistrationScenarioEvent',
    PartnershipStudentRegistration = 'StudentPartnerRegistrationScenarioEvent',
    PartnershipChangePartner = 'ChangeLeadPartnerScenarioAction',
    PartnershipAddTransaction = 'AddLeadPartnershipTransactionScenarioAction',

    // contact
    AddBonusTransaction = 'AddLeadBonusTransactionScenarioAction',

    // cert
    LeadCertCreated = 'LeadCertCreatedScenarioEvent',
    CreateLeadCert = 'CreateLeadCertScenarioAction',

    // chat
    CommentTracking = 'CommentTrackingScenarioEvent',
    ChatChannelMessageReceived = 'ChatChannelMessageReceivedScenarioEvent',
    DeleteChatMessage = 'DeleteChatMessageScenarioAction',

    // gamification
    GamificationProductPurchased = 'GamificationProductPurchasedScenarioEvent',
    GamificationAchievementReceived = 'GamificationAchievementReceivedScenarioEvent',
    AddLeadGamificationAchievement = 'AddLeadGamificationAchievementScenarioAction',
    AddLeadGamificationTransaction = 'AddLeadGamificationTransactionScenarioAction',
    DeleteLeadGamificationAchievement = 'DeleteLeadGamificationAchievementScenarioAction',

    // conversions
    ReachYandexMetrikaGoal = 'ReachYandexMetrikaGoalAction',
    ReachGoogleAnalyticsGoal = 'ReachGoogleAnalyticsGoalAction',
}

export default class ScenarioNode<T = any> extends Entity {
    constructor(node?: Partial<ScenarioNode>) {
        super(node);
        if (node) this.update(node);
    }

    @observable name: string;
    @observable actionType: ScenarioNodeActionType;
    @observable nodeType: ScenarioNodeType;
    @observable settings: ScenarioNodeSettings;
    @observable executionCount: number;
    @observable ports: ScenarioNodePort[];
    @observable useButtonsOneTime: boolean;
    @observable payload: T;
    @observable scenario: Scenario;

    @computed get hasName() {
        return !isEmpty(this.name);
    }

    @computed get buttonPorts() {
        if (!this.ports) return [];
        return this.ports.filter(p => p.type == 'button');
    }

    set buttonPorts(btnPorts: ScenarioNodePort[]) {
        const p = this.ports.filter(x => x.type != 'button');
        this.ports = [...p, ...btnPorts];
    }

    @action
    update(entity: Partial<ScenarioNode<T>>) {
        super.update(entity);
    }

    clone(changes?: Partial<ScenarioNode<T>>): ScenarioNode<T> {
        return new ScenarioNode<T>({
            ...this,
            /**
             * node payload can be a class model with `clone` method in it
             */
            //@ts-ignore
            payload: typeof this.payload?.clone === 'function' ? this.payload.clone() : this.payload,
            ports: this.ports ? this.ports.map(p => p.clone()) : undefined,
            ...changes
        });
    }

    @action
    static fromJson(json: any): ScenarioNode {
        return new ScenarioNode({
            ...json,
            createdDate: json.createdDate ? new Date(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? new Date(json.updatedDate) : undefined,
            settings: json.settingsJson ? ScenarioNodeSettings.fromJson(JSON.parse(json.settingsJson)) : ScenarioNodeSettings.default(),
            // payload: ScenarioNodePayloadFactory.parse(json.actionType, json.payload),
            payload: json.payload ? JSON.parse(json.payload) : null,
            ports: json.ports ? json.ports.map(ScenarioNodePort.fromJson) : undefined,
            scenario: json.scenario ? Scenario.fromJson(json.scenario) : undefined
        });
    }
}